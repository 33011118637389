import { createApp } from 'vue';
import App from './App.vue';
import i18n from './plugins/i18n';

// 引入 Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// 引入 Vue Router
import router from './router';

// 引入 Vue The Mask
import VueTheMask from 'vue-the-mask';

// 使用 Vue3-Toastify
import Toast from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

// 創建 Vue 應用
const app = createApp(App);

// 使用插件
app.use(router)
   .use(i18n)
   .use(VueTheMask)
   .use(Toast, {
      autoClose: 3000,  // 3秒後自動關閉
      position: 'top-right',
      transition: 'bounce',
   });

// 掛載應用
app.mount('#app');