<template>
  <div class="container">
    <!-- Logo 區域 -->
    <header class="mb-4 text-center">
      <img class="logo-image" src="../assets/QuantumLogo.png" alt="Quantum Logo">
    </header>

    <div class="card main-card">
      <div class="card-body">
        <header class="card-header-custom mb-4">
          <h5 class="card-title">{{ $t('biological_radar_fluctuation_detector') }}</h5>
          <h6 class="card-subtitle text-muted">{{ $t('user_information_registration') }}</h6>
        </header>

        <!-- 語言選擇 -->
        <section class="mb-4">
          <languageComp />
        </section>
        
        <!-- 用戶資料表單 -->
        <section class="user-form">
          <!-- 姓名 -->
          <div class="form-group mb-3">
            <label for="name">{{ $t('name_suggestion') }}</label>
            <input 
              v-model="name" 
              type="text" 
              class="form-control" 
              id="name" 
              :placeholder="$t('enter_name')" 
              maxlength="20" 
              required
            >
          </div>
          
          <!-- 電話 -->
          <div class="form-group mb-3">
            <label for="phone">{{ $t('phone') }}*</label>
            <input 
              v-model="phone" 
              type="tel" 
              class="form-control" 
              id="phone" 
              :placeholder="$t('enter_phone_number')" 
              required
            >
          </div>
          
          <!-- 性別 -->
          <div class="form-group mb-3">
            <label>{{ $t('gender') }}*</label>
            <div class="gender-options">
              <div class="form-check">
                <input 
                  v-model="computedGender" 
                  class="form-check-input" 
                  type="radio" 
                  id="male" 
                  value="男性"
                >
                <label class="form-check-label" for="male">{{ $t('male') }}</label>
              </div>
              <div class="form-check">
                <input 
                  v-model="computedGender" 
                  class="form-check-input" 
                  type="radio" 
                  id="female" 
                  value="女性"
                >
                <label class="form-check-label" for="female">{{ $t('female') }}</label>
              </div>
            </div>
          </div>
          
          <!-- 出生日期 -->
          <div class="form-group mb-3">
            <label for="birthdate">{{ $t('birthdate') }}*</label>
            <input 
              v-model="birthdate" 
              v-mask="'####-##-##'" 
              type="text" 
              class="form-control" 
              id="birthdate"
              :max="minBirthdate" 
              placeholder="YYYYMMDD" 
              required
            >
            <small class="text-danger fw-bold">{{ $t('age_recommendation') }}</small>
          </div>
          
          <!-- 身高體重 -->
          <div class="form-row mb-3">
            <div class="row">
              <div class="col-md-6 mb-2 mb-md-0">
                <label for="height">{{ $t('height') }}* cm</label>
                <input 
                  v-model="height" 
                  type="number" 
                  class="form-control" 
                  id="height" 
                  min="150" 
                  max="200" 
                  required
                >
              </div>
              <div class="col-md-6">
                <label for="weight">{{ $t('weight') }}* kg</label>
                <input 
                  v-model="weight" 
                  type="number" 
                  class="form-control" 
                  id="weight" 
                  min="30" 
                  max="120" 
                  required
                >
              </div>
            </div>
          </div>
          
          <!-- 提交按鈕 -->
          <div class="form-group mb-4">
            <button 
              @click="submitForm" 
              class="btn btn-primary btn-block w-100"
            >
              {{ $t('generate_qr_code') }}
            </button>
          </div>
        </section>
        
        <!-- 會員卡與 QR 碼顯示區域 -->
        <section v-if="showQRCode" class="qr-code-section">
          <div class="card mb-3 p-3" id="memberCard">
            <h1 class="card-title mb-3">{{ $t('biological_radar_fluctuation_detector') }}</h1>
            
            <!-- 性別圖標獨立一行 -->
            <div class="text-center mb-3">
              <img :src="genderImage" alt="Gender Image" class="gender-icon">
            </div>
            
            <!-- QR 碼獨立一行 -->
            <div class="text-center">
              <div class="qr-code-container d-flex justify-content-center">
                <QRCodeVue3 
                  :value="qrCode" 
                  :key="qrCode" 
                  :height="450"
                  :width="450"
                  :qr-options="{ errorCorrectionLevel: 'H' }" 
                  :image-options="{ hideBackgroundDots: true, imageSize: 0.2, margin: 10 }" 
                  :image="logoImg"
                  imgclass="w-100"
                />
              </div>
            </div>
            
            <div class="user-info mt-3">
              <p class="text-center tight-spacing" style="font-size: 30px">{{ $t('name') }}: {{ name }}</p>
              <p class="text-center tight-spacing" style="font-size: 30px">{{ $t('gender') }}: {{ computedGender }}</p>
              <p class="text-center tight-spacing" style="font-size: 30px">{{ $t('birthdate') }}: {{ birthdate }}</p>
            </div>
          </div>
          
          <button 
            @click="downloadCardAsJPG" 
            class="btn btn-success btn-block w-100"
          >
            {{ $t('download qr code') }}
          </button>
        </section>
      </div>
    </div>
  </div>
  
  <!-- 版權頁腳 -->
  <CopyrightFooter />
</template>

<script setup>
import { computed, ref } from 'vue'
import QRCodeVue3 from "qrcode-vue3";
import CopyrightFooter from './components/CopyrightFooter'
const logoImg = require('../assets/quantum_logo.svg')
import html2canvas from 'html2canvas';

import languageComp from '@/components/languageComp.vue';

const name = ref("")
const phone = ref("")
const gender = ref("")
const height = ref("")
const weight = ref("")

// 檢查 Brotli Server 連線狀態
import { onMounted } from 'vue';
import axios from 'axios';
import { toast } from 'vue3-toastify';

onMounted(async () => {
  try {
    let response = await axios.get('/brotli/', { timeout: 3000 });

    if (response.status === 200) {
      console.log('✅ Brotli Server 運行中！');
    } else {
      toast.warning('⚠️ Brotli Server 回傳非預期狀態：' + response.status);
    }
  } catch (error) {
    toast.error('❌ Brotli Server 無法連線！請檢查連線狀態。');
    console.error('❌ Brotli Server 錯誤:', error.message);
  }
});

// 計算特定年數前的日期
function getDateYearsAgo(yearsAgo) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - yearsAgo);
  return date.toISOString().split('T')[0];  // 回傳 YYYY-MM-DD 格式
}

// 初始化為 18 年前的日期
const birthdate = ref(getDateYearsAgo(18));

const qrCode = ref("");  // QR Code 資料
const showQRCode = ref(false);  // QR Code 顯示狀態

// 計算最小生日日期（18歲）
const minBirthdate = computed(() => getDateYearsAgo(18));

const submitForm = (async () => {
  if (name.value === '' || gender.value === '' || birthdate.value === '') {
    alert('請填寫必填欄位');
    return;
  }

  // 檢查年齡是否達到十八歲
  const today = new Date();
  const age = today.getFullYear() - new Date(birthdate.value).getFullYear();

  if (age < 18) {
    alert('您的年齡必須達到18歲以上');
    return;
  }

  qrCode.value = ''; // 清空先前的 QR Code
  showQRCode.value = false;
  const id = await searchCustomerID(name.value, gender.value == 'male' ? '1' : '0', birthdate.value, phone.value, height.value, weight.value)
  if (id != false) {
    let data = {
      name: name.value,
      gender: gender.value,
      birthdate: birthdate.value,
      phone: phone.value,
      id: id
    };
    let encodeText = await compressData(data)
    // 產生 QR Code
    if (data.id != undefined && data.id != false) {
      qrCode.value = encodeText.compressed_string
      showQRCode.value = true
    }
    else
      alert("条码生成失败")
  }
  else
    alert("条码生成失败")
    
})

import { apiClient, addCustomer, compressData } from '../services/apiService';

const searchCustomerID = async (name, gender, birthday, cellphone, weight, height) => {
  try {
    const response = await apiClient.post('/SearchPatient', JSON.stringify({ cellphone }));

    const result = response.data.length > 0
      ? response.data.find(element => element[6] === cellphone)
      : null;

    if (result) {
      birthdate.value = result[4].replaceAll('/', '-');
      return result[1];
    } else {
      let registerNewID = await addCustomer({ name, gender, birthday, cellphone, weight, height });
      // 若 registerNewID 為整數，代表註冊成功
      registerNewID = registerNewID.Ind
      if (Number.isInteger(registerNewID)) {
        return registerNewID;
      } else {
        alert('注册失败，请联系网站管理员。');
        return false;
      }
    }
  } catch (error) {
    console.error('查詢顧客 ID 時發生錯誤:', error);
    throw error; // 拋出錯誤供呼叫端處理
  }
}

// 性別資料雙向綁定與轉換
const computedGender = computed({
  get: () => {
    return gender.value === 'male' ? '男性' : gender.value === 'female' ? '女性' : '';
  },
  set: (newValue) => {
    gender.value = newValue === '男性' ? 'male' : newValue === '女性' ? 'female' : '';
  }
});

// 依據性別顯示對應圖片
const genderImage = computed(() => {
  return computedGender.value === '男性' ? '/man.png' : '/woman.png';
});

// 將會員卡轉換為JPG並下載
const downloadCardAsJPG = async () => {
  const cardElement = document.querySelector('#memberCard');
  if (!cardElement) return;
  
  // 取得卡片中所有圖片元素
  const imgElements = cardElement.querySelectorAll('img');
  
  // 確保所有圖片都已載入完成
  const loadPromises = Array.from(imgElements).map(imgElement => {
    console.log(imgElement)
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = resolve;
      img.src = imgElement.src;
    });
  });

  // 等待所有圖片載入完成
  await Promise.all(loadPromises);
  
  const canvas = await html2canvas(cardElement);
  const imgData = canvas.toDataURL('image/jpeg');
  const link = document.createElement('a');
  
  link.href = imgData;
  link.download = 'card.jpg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
</script>


<style scoped>
  .logo-image {
    max-height: 10vh;
    width: auto;
    margin: 0 auto;
  }

  .main-card {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    margin-bottom: 2rem;
  }

  .card-header-custom {
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 1rem;
  }

  .card-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .gender-options {
    display: flex;
    gap: 1.5rem;
    padding-top: 0.5rem;
  }

  .form-group label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .gender-icon {
    width: 230px;
    height: auto;
    display: block;
    margin: 0 auto; /* 使圖標居中 */
  }

  .qr-code-container {
    margin: 1rem auto;
    max-width: 450px; /* 限制 QR 碼的最大寬度 */
  }

  .user-info {
    border-top: 1px solid #f0f0f0;
    padding-top: 1rem;
  }

  .tight-spacing {
    margin-bottom: 0.2rem;
  }

  /* 保留你原始的一些樣式 */
  .container {
    padding: 20px;
  }
</style>