<template>
    <footer class="footer">
      <div class="container">
        <p class="text-center">&copy; 2024 <br> 奇迹量子(北京)科技有限公司. All rights reserved.</p>
        <p style="font-size: xx-small; color: gray; margin-top: -1rem;" class="text-center">20250309</p>
      </div>
    </footer>
  </template>
  
  <style scoped>
  .footer {
    padding: 20px 0;
    margin-top: 20px;
  }
  
  .text-center {
    text-align: center;
  }
  </style>
  
